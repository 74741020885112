import { PortFormRequestDto } from '@state';
import { portAnalysisApi } from './portAnalysisServiceApi';

const portAPI = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    savePort: build.mutation<void, PortFormRequestDto>({
      query: (portData) => ({
        url: '/secured/port',
        method: 'POST',
        data: portData
      })
    })
  }),
  overrideExisting: false
});

export const { useSavePortMutation } = portAPI;
