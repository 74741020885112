import { axiosBaseQuery, createAxiosInstance } from '@client';
import { getEnvironmentVariables } from '@common';
import { createApi } from '@reduxjs/toolkit/query/react';

const { FORMULA_EXECUTOR_API_OVERRIDE, API_URL } = getEnvironmentVariables();

const axiosFormulaExecutionInstance = createAxiosInstance({
  baseURLParam: `${FORMULA_EXECUTOR_API_OVERRIDE ?? API_URL}`
});

export const portAnalysisApi = createApi({
  reducerPath: 'port-analysis-microservice',
  baseQuery: axiosBaseQuery({
    baseUrl: '/port-analysis',
    customAxiosInstance: axiosFormulaExecutionInstance
  }),
  endpoints: () => ({})
});
