import { CountryResponse } from '@state';
import { portAnalysisApi } from './portAnalysisServiceApi';

const countryApi = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    fetchCountriesByName: build.query<CountryResponse[], string>({
      query: (countryToSearch) => ({
        url: '/secured/country/list',
        params: { name: countryToSearch },
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const {
  useFetchCountriesByNameQuery,
  useLazyFetchCountriesByNameQuery
} = countryApi;
