import { combineReducers } from 'redux';
import { activityTypeConfigApi } from './stores/activity-types';
import {
  adminDaToolPortCallsApi,
  adminDaToolPortCallsSlice,
  adminProfilesApi,
  adminProfilesTableSlice,
  adminSupportBuyerApi,
  buyerPaymentInstructionsApi,
  buyerPaymentInstructionsTableSlice,
  buyerProfileApi,
  buyerTeamsApi,
  buyerTeamsTableSlice,
  charterersTableSlice,
  chartererVesselTypesTableSlice,
  kypApi,
  kypVerificationsApi,
  kypVerificationsTableSlice,
  portsApi,
  portTerminalsTableSlice
} from './stores/admin';
import { adminAccountingCodesApi } from './stores/admin-accounting-codes';
import { adminRegionsApi, adminRegionsSlice } from './stores/admin-regions';
import { adminRulesApi, adminRulesSlice } from './stores/admin-rules';
import { formulaExecutionApi } from './stores/admin/port-analysis/formula-execution/api';
import {
  agentAccountReducer,
  agentAccountStateName
} from './stores/agent-account';
import { appointmentPreviewResponseApi } from './stores/agent-appointment-preview';
import { agentBankAccountsApi } from './stores/agent-bank-accounts';
import { agentProformaSlice } from './stores/agent-proforma';
import { agentRatingApi } from './stores/agent-rating';
import {
  appointmentApi,
  appointmentReducerName,
  appointmentsReducer
} from './stores/appointment';
import { assignmentApi } from './stores/assignment/api';
import { bankCodesApi } from './stores/bank-codes';

import { portCallApi } from '@state/stores/port-call';
import { requestForQuotationApi } from '@state/stores/request-for-quotation';
import { buyerDaToolRequestWizardApi } from './stores/buyer-da-tool-request-wizard/api';
import { buyerDashboardSlice, portCallsApi } from './stores/buyer-dashboard';
import { paymentInstructionsApi } from './stores/buyer-payment-instructions';
import cache from './stores/cache';
import { charterersApi } from './stores/charterers';
import { conversationApi, conversationsSlice } from './stores/conversation';
import { currencyApi } from './stores/currency';
import { daAppBaseGraphqlApi } from './stores/da-app/daAppBaseGraphqlApi';
import { evaluationApi, evaluationSelectionsSlice } from './stores/evaluation';
import { evaluationPageSlice } from './stores/evaluation/reducers';
import {
  expenseEvaluationDocumentTranslationApi,
  expenseEvaluationFormulaApi,
  expenseEvaluationInspectionApi
} from './stores/expense-evaluation-inspection';
import { financialPaymentsApi } from './stores/financial-payments';
import { integrationsApi } from './stores/integrations';
import { logEntriesApi } from './stores/log-entries';
import { metadataSlice } from './stores/metadata';
import { notificationsApi, notificationsSlice } from './stores/notifications';
import { portCostEstimatorApi } from './stores/port-cost-estimator';
import { portlogApi } from './stores/port-log';
import {
  publicRfQPageApi,
  sanitizedRfQPageSlice
} from './stores/publicRfQPage';
import { refSplitApi, secureRefSplitApi, secureSplitApi } from './stores/ref';
import { requestCaseApi } from './stores/request-case';
import { mainRestApi } from './stores/restApi/rest-api';
import { sofBaseGraphqlApi } from './stores/statement-of-facts/graphql/sofBaseGraphqlApi';
import { sofRestApi } from './stores/statement-of-facts/rest';
import { sessionReducer, sessionStateName } from './stores/user';
import { vesselApi } from './stores/vessel';
import { voyageApi, voyageReducer, voyageStateName } from './stores/voyage';
import { portAnalysisApi } from './stores/port-analysis-service/portAnalysisServiceApi';

const reducers = {
  [mainRestApi.reducerPath]: mainRestApi.reducer,
  [voyageStateName]: voyageReducer,
  [sessionStateName]: sessionReducer,
  [appointmentReducerName]: appointmentsReducer,
  [agentAccountStateName]: agentAccountReducer,
  [evaluationSelectionsSlice.name]: evaluationSelectionsSlice.reducer,
  cache,
  [agentProformaSlice.name]: agentProformaSlice.reducer,
  [buyerDashboardSlice.name]: buyerDashboardSlice.reducer,
  [portCallsApi.reducerPath]: portCallsApi.reducer,
  [agentRatingApi.reducerPath]: agentRatingApi.reducer,
  [adminRegionsApi.reducerPath]: adminRegionsApi.reducer,
  [adminRegionsSlice.name]: adminRegionsSlice.reducer,
  [adminRulesApi.reducerPath]: adminRulesApi.reducer,
  [adminRulesSlice.name]: adminRulesSlice.reducer,
  [refSplitApi.reducerPath]: refSplitApi.reducer,
  [secureRefSplitApi.reducerPath]: secureRefSplitApi.reducer,
  [secureSplitApi.reducerPath]: secureSplitApi.reducer,
  [agentBankAccountsApi.reducerPath]: agentBankAccountsApi.reducer,
  [financialPaymentsApi.reducerPath]: financialPaymentsApi.reducer,
  [activityTypeConfigApi.reducerPath]: activityTypeConfigApi.reducer,
  [adminAccountingCodesApi.reducerPath]: adminAccountingCodesApi.reducer,
  [logEntriesApi.reducerPath]: logEntriesApi.reducer,
  [conversationApi.reducerPath]: conversationApi.reducer,
  [conversationsSlice.name]: conversationsSlice.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [notificationsSlice.name]: notificationsSlice.reducer,
  [evaluationApi.reducerPath]: evaluationApi.reducer,
  [evaluationPageSlice.name]: evaluationPageSlice.reducer,
  [adminProfilesApi.reducerPath]: adminProfilesApi.reducer,
  [adminProfilesTableSlice.name]: adminProfilesTableSlice.reducer,
  [expenseEvaluationInspectionApi.reducerPath]:
    expenseEvaluationInspectionApi.reducer,
  [expenseEvaluationFormulaApi.reducerPath]:
    expenseEvaluationFormulaApi.reducer,
  [expenseEvaluationDocumentTranslationApi.reducerPath]:
    expenseEvaluationDocumentTranslationApi.reducer,
  [buyerTeamsApi.reducerPath]: buyerTeamsApi.reducer,
  [buyerTeamsTableSlice.name]: buyerTeamsTableSlice.reducer,
  [paymentInstructionsApi.reducerPath]: paymentInstructionsApi.reducer,
  [adminSupportBuyerApi.reducerPath]: adminSupportBuyerApi.reducer,
  [appointmentPreviewResponseApi.reducerPath]:
    appointmentPreviewResponseApi.reducer,
  [buyerPaymentInstructionsTableSlice.name]:
    buyerPaymentInstructionsTableSlice.reducer,
  [buyerPaymentInstructionsApi.reducerPath]:
    buyerPaymentInstructionsApi.reducer,
  [requestCaseApi.reducerPath]: requestCaseApi.reducer,
  [portsApi.reducerPath]: portsApi.reducer,
  [portTerminalsTableSlice.name]: portTerminalsTableSlice.reducer,
  [kypVerificationsTableSlice.name]: kypVerificationsTableSlice.reducer,
  [kypVerificationsApi.reducerPath]: kypVerificationsApi.reducer,
  [publicRfQPageApi.reducerPath]: publicRfQPageApi.reducer,
  [sanitizedRfQPageSlice.name]: sanitizedRfQPageSlice.reducer,
  [bankCodesApi.reducerPath]: bankCodesApi.reducer,
  [kypApi.reducerPath]: kypApi.reducer,
  [adminDaToolPortCallsApi.reducerPath]: adminDaToolPortCallsApi.reducer,
  [adminDaToolPortCallsSlice.name]: adminDaToolPortCallsSlice.reducer,
  [assignmentApi.reducerPath]: assignmentApi.reducer,
  [portlogApi.reducerPath]: portlogApi.reducer,
  [appointmentApi.reducerPath]: appointmentApi.reducer,
  [metadataSlice.name]: metadataSlice.reducer,
  [vesselApi.reducerPath]: vesselApi.reducer,
  [voyageApi.reducerPath]: voyageApi.reducer,
  [formulaExecutionApi.reducerPath]: formulaExecutionApi.reducer,
  [charterersApi.reducerPath]: charterersApi.reducer,
  [integrationsApi.reducerPath]: integrationsApi.reducer,
  [buyerProfileApi.reducerPath]: buyerProfileApi.reducer,
  [charterersTableSlice.name]: charterersTableSlice.reducer,
  [chartererVesselTypesTableSlice.name]: chartererVesselTypesTableSlice.reducer,
  [portCostEstimatorApi.reducerPath]: portCostEstimatorApi.reducer,
  [sofBaseGraphqlApi.reducerPath]: sofBaseGraphqlApi.reducer,
  [daAppBaseGraphqlApi.reducerPath]: daAppBaseGraphqlApi.reducer,
  [sofRestApi.reducerPath]: sofRestApi.reducer,
  [buyerDaToolRequestWizardApi.reducerPath]:
    buyerDaToolRequestWizardApi.reducer,
  [currencyApi.reducerPath]: currencyApi.reducer,
  [requestForQuotationApi.reducerPath]: requestForQuotationApi.reducer,
  [portCallApi.reducerPath]: portCallApi.reducer,
  [portAnalysisApi.reducerPath]: portAnalysisApi.reducer
};

export const middlewares = [
  mainRestApi.middleware,
  portCallsApi.middleware,
  agentRatingApi.middleware,
  adminRegionsApi.middleware,
  adminRulesApi.middleware,
  refSplitApi.middleware,
  secureRefSplitApi.middleware,
  secureSplitApi.middleware,
  agentBankAccountsApi.middleware,
  financialPaymentsApi.middleware,
  adminAccountingCodesApi.middleware,
  logEntriesApi.middleware,
  conversationApi.middleware,
  notificationsApi.middleware,
  evaluationApi.middleware,
  adminProfilesApi.middleware,
  buyerTeamsApi.middleware,
  adminSupportBuyerApi.middleware,
  expenseEvaluationInspectionApi.middleware,
  expenseEvaluationFormulaApi.middleware,
  expenseEvaluationDocumentTranslationApi.middleware,
  appointmentPreviewResponseApi.middleware,
  paymentInstructionsApi.middleware,
  buyerPaymentInstructionsApi.middleware,
  requestCaseApi.middleware,
  portsApi.middleware,
  kypVerificationsApi.middleware,
  publicRfQPageApi.middleware,
  bankCodesApi.middleware,
  kypApi.middleware,
  adminDaToolPortCallsApi.middleware,
  activityTypeConfigApi.middleware,
  appointmentApi.middleware,
  assignmentApi.middleware,
  portlogApi.middleware,
  vesselApi.middleware,
  voyageApi.middleware,
  formulaExecutionApi.middleware,
  charterersApi.middleware,
  integrationsApi.middleware,
  buyerProfileApi.middleware,
  portCostEstimatorApi.middleware,
  sofBaseGraphqlApi.middleware,
  daAppBaseGraphqlApi.middleware,
  buyerDaToolRequestWizardApi.middleware,
  currencyApi.middleware,
  sofRestApi.middleware,
  requestForQuotationApi.middleware,
  portCallApi.middleware,
  portAnalysisApi.middleware
];

export const rootReducer = combineReducers(reducers);
