import { RefData } from '@state';
import { portAnalysisApi } from './portAnalysisServiceApi';

const refDataPath = '/ref-data';

const refDataApi = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    fetchSegments: build.query<RefData[], void>({
      query: () => ({
        url: refDataPath + '/segments',
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchSegmentsQuery, useLazyFetchSegmentsQuery } = refDataApi;
